import { AuthenticationConfigNames } from '../app/core/authenticationConfigNames';
const clientUrl = 'https://sqeptech-esqep-demo-a.azurewebsites.net';
const apiUrl = 'https://sqeptech-esqep-demo-a-api.azurewebsites.net';
const serverUrl = 'https://sqeptech-esqep-demo-a-api-dotnet.azurewebsites.net';

export const environment = {
  production: false,
  envName: 'demo-a',
  shareUrl: 'https://sqeptech-esqep-demo-a-api.azurewebsites.net/shared',
  apiUrl: apiUrl + '/esqep/api/v1.0',
  serverUrl: serverUrl + '/api',
  clientUrl: clientUrl,
  auth: [
    {
      authConfigName: AuthenticationConfigNames.CSOD,
      enabled: true,
      buttonTitle: 'Login with Cornerstone',
      logoutUrl: '',
      spConfigName: '',
    },
    {
      authConfigName: AuthenticationConfigNames.OKTA,
      enabled: true,
      buttonTitle: 'Login with Okta',
      clientId: '0oa5oupk22Ln3TUa75d7',
      openIdIssuer: 'https://dev-71733550.okta.com/oauth2/default',
      sqeptechClientId: '',
      sqeptechOpenIdIssuer: '',
      allowedOrigins: [clientUrl, apiUrl, serverUrl],
    },
    {
      authConfigName: AuthenticationConfigNames.ENTRA,
      enabled: true,
      buttonTitle: 'Login',
      clientId: '9ea010b4-900e-491e-a594-f385e33d551d',
      allowedAzureTenants: [
        'd320fce4-2119-4307-8597-5b2363fcc067',
        'c82dd3cd-7edf-45b7-98d9-833023809c53',
      ],
    },
  ],
  loggingLevel: 3,
  skillMatrixHeadings: ['PM', 'TL', 'Staff'],
  // skillMatrixFaId: 23,
  skillMatrixFaId: 983,
  clientLogoAsset: 'esqep_icon.png',
  navbarBgClass: 'bg-sqeptech',
  userGroups: [
    {
      id: '103eead7-1830-457b-8ba3-1a1e9ef53072',
      name: 'eSQEP-users',
      isAdmin: false,
    },
    {
      id: 'c645c0db-2c14-4421-850a-8f4699f9f9cf',
      name: 'eSQEP-admins',
      isAdmin: true,
    },
  ],
  loginPageMessage: 'Welcome to your eSQEP Portal',
  roleManagementTabs: ['details', 'competencies', 'training', 'assignment'],
  homeCards: [
    'myActivitySummary',
    'myClaimActions',
    'myJobRoleProfile',
    'myAssignment',
    'myTrainingCompletion',
  ],
  clientAccount: {
    menu: 'demo',
  },
  showRoleManagementAiPrompt: true,
};
